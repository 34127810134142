import { render, staticRenderFns } from "./examineStudent.vue?vue&type=template&id=351b8516&scoped=true"
import script from "./examineStudent.vue?vue&type=script&lang=js"
export * from "./examineStudent.vue?vue&type=script&lang=js"
import style0 from "./examineStudent.vue?vue&type=style&index=0&id=351b8516&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "351b8516",
  null
  
)

export default component.exports